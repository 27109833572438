// export const baseURL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:9999/"
//     : "http://shkjgw.shkjem.com/api/";
//export const baseURL = "http://localhost:8081/";
//export const baseURL = "http://47.103.30.66/offical/";
export const baseURL = "http://www.jszhzz.cn/offical/";

// export const imgserver = "http://localhost:8081/";
//export const imgserver = "http://47.103.30.66/offical/";
export const imgserver = "http://www.jszhzz.cn/offical/";

export const videoURL = "http://www.jszhzz.cn/offical/";

