import Vue from 'vue'
import Router from 'vue-router'
import { scrollTo } from '@/utils/scroll-to'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [{
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./views/News.vue'),
    },
    {
      path: '/newsdetails/:id',
      name: 'newsdetails',
      component: () => import('./views/NewsDetails.vue'),
    },
    {
      path: '/product',
      name: 'product',
      component: () => import('./views/Product.vue'),
    },
    {
      path: '/case',
      name: 'case',
      component: () => import('./views/Case.vue')
    },
    {
      path: '/casedetails/:id',
      name: 'casedetails',
      component: () => import('./views/CaseDetails.vue')
    },
    {
      path: '/goin',
      name: 'goin',
      component: () => import('./views/GoIn.vue')
    },
    {
      path: '/hzhb',
      name: 'hzhb',
      component: () => import('./views/hzhb.vue')
    },
    {
      path: '/zizhi',
      name: 'zizhi',
      component: () => import('./views/zizhi.vue')
    },
    {
      path: '/biaozhunhua',
      name: 'biaozhunhua',
      component: () => import('./views/biaozhunhua.vue')
    },
    {
      path: '/bim',
      name: 'bim',
      component: () => import('./views/bim.vue')
    },
    {
      path: '/zhgd',
      name: 'zhgd',
      component: () => import('./views/zhgd.vue')
    },
    {
      path: '/team',
      name: 'team',
      component: () => import('./views/team.vue')
    },
    {
      path: '/ybyl',
      name: 'ybyl',
      component: () => import('./views/ybyl.vue')
    },
    {
      path: '/rongyu',
      name: 'rongyu',
      component: () => import('./views/rongyu.vue')
    },
    {
      path: '/qyjg',
      name: 'qyjg',
      component: () => import('./views/qyjg.vue')
    },
    {
      path: '/lianxiwomen',
      name: 'lianxiwomen',
      component: () => import('./views/lianxiwomen.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      meta: {
        requireAuth: true
      },
      component: () => import('./views/Admin.vue'),
      children: [{
          path: '/admin/user',
          name: 'user',
          component: () => import('./views/Admin/User.vue')
        },
        {
          path: '/admin/news',
          name: 'new',
          component: () => import('./views/Admin/News.vue')
        },
        {
          path: '/admin/cases',
          name: 'cases',
          component: () => import('./views/Admin/Cases.vue')
        },
        {
          path: '/admin/team',
          name: 'team',
          component: () => import('./views/Admin/Team.vue')
        },
        {
          path: '/admin/course',
          name: 'course',
          component: () => import('./views/Admin/Course.vue')
        },
        {
          path: '/admin/enterprise',
          name: 'enterprise',
          component: () => import('./views/Admin/Enterprise.vue')
        },
        {
          path: '/admin/honor',
          name: 'honor',
          component: () => import('./views/Admin/Honor.vue')
        },
        {
          path: '/admin/dictionary',
          name: 'dictionary',
          component: () => import('./views/Admin/Dictionary.vue')
        }
      ]
    }
  ]
})

// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
  // 判断是否需要登录权限
  if (to.matched.some(res => res.meta.requireAuth)) {
    // 判断是否登录
    if (sessionStorage.getItem('token')) {
      next()
    } else {
      // 没登录则跳转到登录界面
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})
router.afterEach(() => {
  scrollTo(0, 0)
});
export default router


