<template>
  <el-menu default-active="/" :mode="mode" @select="handleSelect" router>
    <el-menu-item index="/">首页</el-menu-item>
    <el-submenu index="2">
      <template slot="title">关于中慧</template>
        <el-menu-item index="/goin" >中慧简介</el-menu-item>
        <el-menu-item index="/rongyu">企业荣誉</el-menu-item>
      <el-menu-item index="/qyjg">企业架构</el-menu-item>
      <el-menu-item index="/zizhi">企业资质</el-menu-item>
      <el-menu-item index="/team">团队风采</el-menu-item>
      <el-menu-item index="/hzhb">合作伙伴</el-menu-item>
    </el-submenu>
    <el-menu-item index="/case">工程案例</el-menu-item>
    <el-menu-item index="/news">新闻资讯</el-menu-item>
<!--    <el-menu-item index="/product">产业板块</el-menu-item>-->
<!--    <el-submenu index="3">
      <template slot="title">产业板块</template>
      <el-menu-item index="/rongyu">安装事业部</el-menu-item>
      <el-menu-item index="/rongyu">土建事业部</el-menu-item>
      <el-menu-item index="/zizhi">景观事业部</el-menu-item>
      <el-menu-item index="/zizhi">装修事业部</el-menu-item>
      <el-menu-item index="/zizhi">幕墙事业部</el-menu-item>
      <el-menu-item index="/zizhi">城市工厂</el-menu-item>
    </el-submenu>-->
    <el-submenu index="4">
      <template slot="title">管理与品质</template>
      <el-menu-item index="/biaozhunhua">标准化</el-menu-item>
      <el-menu-item index="/bim">BIM技术应用</el-menu-item>
      <el-menu-item index="/zhgd">智慧工地管理</el-menu-item>
      <el-menu-item index="/ybyl">样板引路制度</el-menu-item>
    </el-submenu>

    <el-menu-item index="/lianxiwomen">联系我们</el-menu-item>
  </el-menu>
</template>

<script>
export default {
  props: {
    mode: {
      type: String
    },
    handleSelect: {
      type: Function
    }
  }
};
</script>

<style>
</style>