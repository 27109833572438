<template>
  <div class="footer">
    <div class="footer-content">
      <ul class="content-nav">
        <li>
          <p>中慧智造</p>
          <span><el-link @click='goHref("goin")' > 发展历程 </el-link> </span>
          <span><el-link @click='goHref("case")' > 工程案例 </el-link> </span>
          <span><el-link @click='goHref("rongyu")' > 企业荣誉 </el-link></span>
          <span><el-link @click='goHref("team")' > 团队风采 </el-link></span>
          <span><el-link @click='goHref("hzhb")' > 合作伙伴 </el-link></span>
        </li>
        <li>
          <p>新闻资讯</p>
          <span><el-link @click='goHref("news")' >公司新闻</el-link></span>
          <span><el-link @click='goHref("news")' >行业动态</el-link></span>
        </li>
        <li>
          <p>品质与管理</p>
          <span><el-link @click='goHref("biaozhunhua")' > 标准化</el-link></span>
          <span><el-link @click='goHref("bim")' > BIM技术应用</el-link></span>
          <span><el-link @click='goHref("zhgd")' > 智慧工地管理</el-link></span>
          <span><el-link @click='goHref("ybyl")' > 样板引路制度</el-link></span>
        </li>
        <li class="contact">
          <p>联系我们</p>
          <span><el-link @click='goHref("lianxiwomen")' >邮箱：15151314462@163.com </el-link></span>
          <span><el-link @click='goHref("lianxiwomen")' >电话：0513-88891330</el-link></span>
          <span><el-link @click='goHref("lianxiwomen")' >地址：海安市黄海大道（中）165号中杰文化科技大厦</el-link></span>
        </li>
      </ul>
<!--
      <img src="@/assets/img/ercode.png" alt />
-->
    </div>
    <div class="copyright">
      <span>© 2022-2023 江苏中慧传承工程建设有限公司版权所有</span>
<!--      <span><el-link>公安备案号XXXXX</el-link>  </span>-->
      <span> <el-link @click="goba">苏ICP备2023011811号</el-link></span>
    </div>
  </div>
</template>

<script>
export default {

  methods: {
    goHref(n){
      this.$router.push({ path:'/'+n  })
    },
    goba(){
      window.open('https://beian.miit.gov.cn');
    }
  }
};
</script>

<style lang="scss">
.footer {
  width: 100%;
  height: 300px;
  overflow: hidden;
  background-color: #14679f;
  &-content {
    width: 1240px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    .content-nav {
      display: flex;
      justify-content: space-around;
      li {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        //justify-content: center;
        align-items: flex-start;
        p {
          font-size: 20px;
          color: #d4edff;
          padding: 10px 0;
        }
        span {
          color: #f7f7f7;
          font-weight: 300;
          padding: 5px 0;
        }
      }
    }
    img {
      width: 170px;
      height: 170px;
      padding: 10px;
    }
  }
  .copyright {
    height: 30px;
    background: #125688;
    span {
      width: 100%;
      font-size: 14px;
      color: #fff;
      line-height: 30px;
      padding-left: 10px;
    }
    text-align: center;
  }
}
.mobile {
  .footer {
    height: auto;
    .footer-content {
      width: 100%;
      display: block;
      .content-nav {
        display: block;
        li {
          display: block;
          width: 90%;
          margin: auto;
          padding: 0 10px;
          span {
            padding: 5px;
          }
        }
        .contact {
          span {
            display: block;
          }
        }
      }
      img {
        margin: auto;
        display: block;
      }
    }
    .copyright{
      text-align: center;
    }
  }
}
</style>